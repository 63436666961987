import React from 'react'
import { ParaSmall } from '@papertrail/styleguide'
import { Subscription } from 'src/types'

type Props = {
  subscription: Subscription
}

export default function SubscriptionAllowances(props: Props) {
  const { subscription } = props
  return (
    <ParaSmall>
      {subscription.allowances.users} Users
      <span> &bull; </span>
      {subscription.allowances.unlimitedViewOnlyUsers ? 'Unlimited' : subscription.allowances.viewonlyUsers} View only
      users
      <span> &bull; </span>
      {subscription.allowances.unlimitedRecords ? 'Unlimited' : subscription.allowances.records} Records
    </ParaSmall>
  )
}
