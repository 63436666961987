import React, { useEffect } from 'react'
import { Account } from '../types'
import { useApiGetAccountFilters } from '../hooks'
import AccountCard from './AccountCard'
import AccountRow from './AccountRow'
import { useHistory } from 'react-router-dom'
import { SupportPopupConfig } from './SupportPopup'

type Props = {
  account: Account
  view: string
  isMobile: boolean
  showSupportPopup: (config: SupportPopupConfig) => void
  demoActivated: () => void
  reloadAccounts: () => void
}

export default function AccountWrapper(props: Props) {
  const { account, view, showSupportPopup, isMobile, demoActivated, reloadAccounts } = props
  const [filtersState, loadFilters] = useApiGetAccountFilters(account.id, 1000)
  const history = useHistory()
  let noAccessAccount = false

  // Re-use filters API call to determine access to account
  if (filtersState.isError) {
    if (filtersState.error && filtersState.error.errorCode === 'user_does_not_belong_to_account') {
      noAccessAccount = true
    }
  }

  useEffect(() => {
    if (account.active) {
      loadFilters()
    }
  }, [])

  function onCardClick() {
    if (!account.active) {
      if (account.isDemo) {
        showSupportPopup({
          title: 'Expired Demo Account',
          body: 'This demo account has expired. Please get in touch to request reactivation of the demo.'
        })
      } else {
        showSupportPopup({
          title: 'This account is deactivated',
          body: 'This account has been locked either by an account owner or by its Papertrail subscription being terminated. Please get in touch to discuss reactivation.',
          intercom: 'I would like to activate an account'
        })
      }
    } else {
      if (noAccessAccount) {
        showSupportPopup({
          title: 'No access to Account',
          body: "You don't have access to this account. Either you have not been invited, or your access has been suspended."
        })
      } else {
        history.push(`/accounts/${account.id}`)
      }
    }
  }

  function onDemoActivated(error?: string) {
    if (!error) {
      demoActivated()
    } else if (error === 'user_is_not_an_owner') {
      showSupportPopup({
        title: 'Unable To Activate Demo Account',
        body: 'Your user is not an account owner.',
        intercom: 'User is not an account owner.'
      })
    } else if (error === 'member_limit_exceeded' || error === 'view_only_member_limit_exceeded') {
      showSupportPopup({
        title: 'Unable To Activate Demo Account',
        body: 'Member limit exceeded.',
        intercom: 'Member limit exceeded.'
      })
    } else if (error === 'record_limit_exceeded') {
      showSupportPopup({
        title: 'Unable To Activate Demo Account',
        body: 'Record limit exceeded.',
        intercom: 'Record limit exceeded.'
      })
    } else if (error === 'template_not_available') {
      showSupportPopup({
        title: 'Unable To Activate Demo Account',
        body: 'Module not available.',
        intercom: 'Module not available.'
      })
    } else {
      showSupportPopup({
        title: 'Unable To Activate Demo Account',
        body: 'We were unable to activate demo account.',
        intercom: 'Unable to activate demo account.'
      })
    }
  }

  if (view === 'grid' || isMobile) {
    return (
      <AccountCard
        account={account}
        filtersState={filtersState}
        onClick={onCardClick}
        reloadAccounts={reloadAccounts}
        demoActivated={onDemoActivated}
      />
    )
  } else {
    return (
      <AccountRow
        account={account}
        filtersState={filtersState}
        onClick={onCardClick}
        reloadAccounts={reloadAccounts}
        demoActivated={onDemoActivated}
      />
    )
  }
}
