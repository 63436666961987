import * as React from 'react'
import { useEffect, useCallback } from 'react'
import { Account } from '../types'
import { useCloneAccount, useApiGetAccount } from '../hooks'
import { useApiGet, sleep } from '@papertrail/web3-utils'
import { debounce } from 'lodash'

import {
  ButtonPrimary,
  ButtonSecondary,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Formik,
  FormFieldText,
  FormFieldSearch,
  FormFieldCheckbox,
  Form,
  Box,
  AlertWarning,
  AlertInfo,
  Loading,
  Stack
} from '@papertrail/styleguide'

type Props = {
  show: boolean
  account: Account
  onClose: () => void
  reloadAccounts: () => void
}

export default function AccountCloneDialog(props: Props) {
  const { account, onClose, reloadAccounts } = props
  const [cloneAccountState, postCloneAccount] = useCloneAccount()
  const [subscriptionState, loadSubscriptions] = useApiGet('/subscriptions', subscriptionsMapper)
  const [singleSubState, checkSingleSub] = useApiGet('/subscriptions', subscriptionsMapper)
  const clonedAccountId = cloneAccountState.isLoaded ? cloneAccountState.data.data.id : undefined
  const [accountState, getAccount] = useApiGetAccount(clonedAccountId)

  useEffect(() => {
    checkSingleSub({ limit: 2 })
    loadSubscriptions({ limit: 5 })
  }, [])

  useEffect(() => {
    if (accountState.isLoaded) {
      reloadAccounts()
      onClose()
    } else if (accountState.isError) {
      // waiting 500ms and retrying
      sleep(500)
      getAccount({})
    }
  }, [accountState.isLoaded, accountState.isError])

  useEffect(() => {
    if (cloneAccountState.isLoaded) {
      getAccount({})
    }
  }, [cloneAccountState.isLoaded])

  function subscriptionsMapper(data) {
    return data.data.map((sub) => {
      return {
        id: sub.id,
        label: sub.name
      }
    })
  }

  function validate(values) {
    const errors = {} as any

    if (!values.newAccountName || !values.newAccountName.length) {
      errors.newAccountName = 'New account name mandatory.'
    }

    if (!values.subscription || values.subscription.length === 0) {
      errors.subscription = 'Subscription mandatory.'
    }

    return errors
  }

  const isSingleSub = singleSubState.isLoaded && singleSubState.data.length === 1
  const singleSubId = isSingleSub ? singleSubState.data[0].id : undefined

  function submit(data) {
    const subscription = data.subscription ? data.subscription : singleSubId

    postCloneAccount(
      account.id,
      data.newAccountName,
      subscription,
      data.isDemo,
      data.includeFolders,
      data.includeRecords
    )
  }

  function refreshSubs(str: string) {
    loadSubscriptions({ filter: `name~=${str}`, limit: 5 })
  }

  const refreshSubsCallback = useCallback(debounce(refreshSubs, 500), [])

  function onSearchChange(str: string) {
    refreshSubsCallback(str)
  }

  const initialValues = {
    newAccountName: account.accountName + ' copy',
    subscription: isSingleSub ? singleSubId : undefined,
    isDemo: false,
    includeRecords: false,
    includeFolders: true
  }

  return (
    <Dialog open={true} onClose={submit}>
      <DialogTitle>Clone {account.accountName}</DialogTitle>
      {singleSubState.isLoading && (
        <DialogContent sx={{ width: '550px' }}>
          <Loading />
        </DialogContent>
      )}

      {singleSubState.isLoaded && (
        <Formik initialValues={initialValues} onSubmit={submit} validate={validate} validateOnBlur={true}>
          {(formikProps) => (
            <Form>
              <DialogContent sx={{ width: '550px' }}>
                <Stack>
                  <FormFieldText
                    name="newAccountName"
                    mandatory
                    label={'New Account Name'}
                    data-cy="account"
                    placeholder={'New account name'}
                  />

                  {!isSingleSub && (
                    <FormFieldSearch
                      name="subscription"
                      mandatory
                      label={'Subscription'}
                      data-cy="subscription"
                      disabled={!!isSingleSub}
                      options={subscriptionState.data ? subscriptionState.data : []}
                      onFieldChange={onSearchChange}
                      placeholder={'Pick subscription'}
                    />
                  )}

                  <Box mt={1}>
                    <FormFieldCheckbox label="CLONE FOLDERS" data-cy="include-folders" name="includeFolders" />

                    {formikProps.values.includeFolders && (
                      <FormFieldCheckbox label="CLONE RECORDS" data-cy="include-records" name="includeRecords" />
                    )}

                    <FormFieldCheckbox label="MAKE THIS A DEMO ACCOUNT" name="isDemo" />
                  </Box>
                </Stack>

                {cloneAccountState.isLoading && <Loading />}

                {cloneAccountState.isLoaded && (
                  <AlertInfo>We have successfully cloned your account. It might take a while to show up.</AlertInfo>
                )}

                {cloneAccountState.isError && <AlertWarning>We were unable to clone your account.</AlertWarning>}
              </DialogContent>
              <DialogActions>
                <ButtonSecondary onClick={() => onClose()}>Cancel</ButtonSecondary>
                <ButtonPrimary data-testid="clone" disabled={cloneAccountState.isLoading} isSubmit={true}>
                  Clone
                </ButtonPrimary>
              </DialogActions>
            </Form>
          )}
        </Formik>
      )}
    </Dialog>
  )
}
