import React from 'react'
import { Subscription } from 'src/types'
import slugify from 'slugify'
import SubscriptionAllowances from './SubscriptionAllowances'
import SubscriptionStatusLabel from './SubscriptionStatusLabel'
import { Card, CardHeader, CardContent, Avatar, SubscriptionIcon, Stack } from '@papertrail/styleguide'
import SubscriptionActions from './SubscriptionActions'

type Props = {
  subscription: Subscription
  onClick: () => void
}

const cardStyles = {
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between'
}

export default function SubscriptionCard(props: Props) {
  const { subscription, onClick } = props
  return (
    <Card
      variant="outlined"
      sx={cardStyles}
      onClick={onClick}
      data-cy="subscription-card"
      data-testid={'subscription-card-' + slugify(subscription.subName.toLowerCase())}>
      <CardHeader
        action={<SubscriptionActions subscription={subscription} />}
        avatar={
          <Avatar>
            <SubscriptionIcon fontSize={'large'} />
          </Avatar>
        }
        title={subscription.subName}
        subheader={subscription.planName}
      />

      <CardContent>
        <Stack spacing={1} justifyContent={'flex-end'} height={'100%'}>
          <SubscriptionStatusLabel subscription={subscription} />
          <SubscriptionAllowances subscription={subscription} />
        </Stack>
      </CardContent>
    </Card>
  )
}
