import * as React from 'react'
import { Account } from '../types'
import { useApiActivateCalendar, useApiUpdateCalendar, useApiRemoveCalendar } from '../hooks'
import CopyLink from './CopyLink'

import {
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  Box,
  Formik,
  Form,
  DialogActions,
  ButtonPrimary,
  ButtonSecondary,
  FormFieldText,
  FormFieldSelect,
  ActionSuccess,
  ActionError,
  ActionLoading
} from '@papertrail/styleguide'

type Props = {
  account: Account
  onClose: () => void
  reloadAccounts: () => void
}

export default function CalendarDialog(props: Props) {
  const { onClose, reloadAccounts, account } = props
  const memberId = account.member ? account.member.id : null
  const [activateState, activateCalendar] = useApiActivateCalendar(account.id, memberId)
  const hasCalendar = !!(account.member && account.member.member_calendar)
  const calendarSubscriptionId = hasCalendar ? account.member.member_calendar.data.id : ''
  const [updateState, updateCalendar] = useApiUpdateCalendar(account.id, memberId, calendarSubscriptionId)
  const [removeState, removeCalendar] = useApiRemoveCalendar(account.id, memberId, calendarSubscriptionId)

  function onCloseLocal() {
    if (reloadAccounts) {
      reloadAccounts()
    }

    onClose()
  }

  function onSubmit(values) {
    const { accountName, months, inspections } = values
    if (!hasCalendar) {
      activateCalendar(accountName, months, inspections)
    } else {
      updateCalendar(accountName, months, inspections)
    }
  }

  let initialValues = {
    accountName: `${account.accountName}_Calendar`,
    months: 3,
    inspections: 6
  }

  let calendarUrl
  let webcalUrl

  if (hasCalendar) {
    const calendarData = account.member.member_calendar.data
    initialValues = {
      accountName: calendarData.calendar_name,
      months: calendarData.search_period_months,
      inspections: calendarData.collate_record_threshold
    }
    calendarUrl = calendarData.url
    webcalUrl = calendarData.url.replace('https://', 'webcal://').replace('http://', 'webcal://') // HTTP / HTTPS
  }

  function onUnsubscribe() {
    removeCalendar()
  }

  function openCalendar() {
    if (activateState.isLoaded) {
      const url = activateState.data
        ? activateState.data.data.url.replace('https://', 'webcal://').replace('http://', 'webcal://')
        : null
      window.open(url)
    } else if (hasCalendar) {
      window.open(webcalUrl)
    }
  }

  const showMainContent =
    !updateState.isLoaded &&
    !updateState.isError &&
    !removeState.isLoaded &&
    !removeState.isLoading &&
    !removeState.isError &&
    !activateState.isLoaded &&
    !activateState.isError

  const primaryButtonText =
    updateState.isLoading || activateState.isLoading ? 'Please wait...' : hasCalendar ? 'Update' : 'Submit'

  return (
    <Dialog open={true} onClose={onCloseLocal}>
      {!hasCalendar && <DialogTitle>{`Subscribe to ${account.accountName} calendar`}</DialogTitle>}
      {hasCalendar && <DialogTitle>{`Manage ${account.accountName} calendar`}</DialogTitle>}
      {showMainContent && (
        <Formik initialValues={initialValues} onSubmit={onSubmit}>
          <Form>
            <DialogContent>
              <Stack>
                <Box>
                  <b>Caution!</b> You are about to activate a calendar link which shares your Papertrail data with your
                  calendar application. Please do not share this link with others.
                </Box>
                <br />
                <FormFieldText
                  name="accountName"
                  mandatory
                  label={'Account Calendar'}
                  data-cy="account"
                  placeholder={'Enter calendar name...'}
                />
                <FormFieldSelect
                  label="Months to display in calendar"
                  mandatory
                  inline
                  placeholder="Please select..."
                  options={[
                    { value: '1', label: '1' },
                    { value: '2', label: '2' },
                    { value: '3', label: '3' },
                    { value: '4', label: '4' },
                    { value: '5', label: '5' },
                    { value: '6', label: '6' },
                    { value: '7', label: '7' },
                    { value: '8', label: '8' },
                    { value: '9', label: '9' },
                    { value: '10', label: '10' },
                    { value: '11', label: '11' },
                    { value: '12', label: '12' }
                  ]}
                  name="months"
                />
                <FormFieldSelect
                  label="Inspection(s) or more per day will be shown in one calendar event"
                  mandatory
                  inline
                  placeholder="Please select..."
                  options={[
                    { value: '1', label: '1' },
                    { value: '2', label: '2' },
                    { value: '3', label: '3' },
                    { value: '4', label: '4' },
                    { value: '5', label: '5' },
                    { value: '6', label: '6' },
                    { value: '7', label: '7' },
                    { value: '8', label: '8' },
                    { value: '9', label: '9' },
                    { value: '10', label: '10' }
                  ]}
                  name="inspections"
                />
              </Stack>
              {hasCalendar && <CopyLink url={calendarUrl} />}
              <Stack>
                {hasCalendar && (
                  <Stack flexDirection="row" sx={{ marginTop: 1 }}>
                    <ButtonPrimary onClick={openCalendar}>Open calendar</ButtonPrimary>
                    &nbsp;
                    <ButtonSecondary onClick={onUnsubscribe}>Unsubscribe</ButtonSecondary>
                  </Stack>
                )}
              </Stack>
              <Stack sx={{ marginTop: 2 }}>
                {activateState.isError && (
                  <Box>
                    Unable to activate calendar on this account. Make sure the account has the feature flag turned on.
                  </Box>
                )}
              </Stack>
            </DialogContent>
            <DialogActions>
              <ButtonSecondary onClick={() => onCloseLocal()}>Cancel</ButtonSecondary>
              <ButtonPrimary
                disabled={
                  updateState.isLoading || updateState.isLoaded || removeState.isLoading || removeState.isLoaded
                }
                isSubmit>
                {primaryButtonText}
              </ButtonPrimary>
            </DialogActions>
          </Form>
        </Formik>
      )}

      {!showMainContent && (
        <>
          {activateState.isLoaded && (
            <DialogContent>
              <Stack>
                <Stack alignItems="center">
                  <ActionSuccess
                    title="Activated successfully."
                    message={`Your calendar subscription has been activated, please click on open calendar to open your calendar, or copy the URL below and add it to your calendar app.`}
                  />
                  <Box sx={{ marginTop: 1 }}>
                    <ButtonPrimary onClick={openCalendar}>Open calendar</ButtonPrimary>
                  </Box>
                  <Box alignSelf={'stretch'} marginTop={2}>
                    <CopyLink url={activateState.data.data.url} />
                  </Box>
                </Stack>
              </Stack>
            </DialogContent>
          )}

          {activateState.isError && (
            <DialogContent>
              <ActionError
                title="Error Subscribing Calendar"
                message="Unable to activate calendar on this account. Make sure the account has the feature flag turned on."
              />
            </DialogContent>
          )}

          {updateState.isLoaded && (
            <DialogContent>
              <ActionSuccess title="Updated Successfully." message="Your calendar has been updated successfully." />
            </DialogContent>
          )}

          {removeState.isLoaded && (
            <DialogContent>
              <Box>
                <ActionSuccess
                  title="Unsubscribed Successfully"
                  message="We have successfully unsubscribed from your calendar."
                />
              </Box>
            </DialogContent>
          )}

          {removeState.isLoading && (
            <DialogContent>
              <ActionLoading message="Please wait..." />
            </DialogContent>
          )}

          <DialogActions>
            <ButtonSecondary disabled={removeState.isLoading} onClick={() => onCloseLocal()}>
              Close
            </ButtonSecondary>
          </DialogActions>
        </>
      )}
    </Dialog>
  )
}
