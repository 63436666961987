import React, { useMemo } from 'react'
import { Box, colours, ToolTip } from '@papertrail/styleguide'
import { FetchState } from '@papertrail/web3-utils'
import { Filter } from '../types'
import { startCase } from 'lodash'

type Props = {
  filtersState: FetchState<Filter[]>
  profile: 'warn' | 'alert'
}
export function FilterDisplay(props: Props) {
  const { profile, filtersState } = props

  const colour = profile === 'warn' ? colours.yellow.y400 : colours.red.r400

  const filteredFilters = useMemo(
    () => (filtersState.isLoaded ? filtersState.data.filter((f) => f.profile === profile) : []),
    [filtersState]
  )

  function getToolTip() {
    if (filteredFilters.length > 0) {
      return (
        <>
          {filteredFilters.map((filter) => (
            <Box key={filter.id}>
              {filter.count} {filter.name}
            </Box>
          ))}
        </>
      )
    } else {
      return 'No filter data to show.'
    }
  }

  function filterSum() {
    let sum = 0

    for (const filter of filteredFilters) {
      sum += filter.count
    }

    return `${sum} ${startCase(profile)}`
  }

  return (
    <ToolTip title={getToolTip()}>
      <span style={{ color: colour }}>{filterSum()}</span>
    </ToolTip>
  )
}
