import React from 'react'
import { Label, Stack } from '@papertrail/styleguide'
import { Account } from '../types'
import { PapertrailSession } from '@papertrail/web3-session'
import { formatDuration } from '@papertrail/web3-utils'

type Props = {
  account: Account
}

export default function AccountStatusLabel(props: Props) {
  const { account } = props

  function getPeriod(datetime) {
    const dateTimeConfig = PapertrailSession.getDateTimeConfig()
    return formatDuration(datetime, dateTimeConfig, true)
  }

  if (account.isDemo) {
    if (account.active) {
      return (
        <Stack spacing={1} direction="row" alignItems={'center'}>
          <Label variant="warning" text="Demo account" />
          {account.expiresAt && <span>Expires {getPeriod(account.expiresAt)}</span>}
        </Stack>
      )
    } else {
      return (
        <Stack spacing={1} direction="row" alignItems={'center'}>
          <Label variant="warning" text="Expired demo" /> <span>Expired {getPeriod(account.deactivatedAt)}</span>
        </Stack>
      )
    }
  } else if (!account.active) {
    return (
      <Stack spacing={1} direction="row" alignItems={'center'}>
        <Label variant="error" text="Inactive" /> <span>Deactivated {getPeriod(account.deactivatedAt)}</span>
      </Stack>
    )
  } else {
    return null
  }
}
