import React from 'react'
import { Avatar } from '@papertrail/styleguide'
import { Account } from '../types'

type Props = {
  account: Account
}

export default function AccountAvatar(props: Props) {
  const { account } = props

  if (account.logo) {
    return <Avatar src={account.logo} />
  } else {
    return <Avatar src="https://cdn.papertrail.io/images/logo-silvermist.svg" />
  }
}
