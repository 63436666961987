import { GridViewIcon, ListViewIcon, ToggleButtonGroup, ToggleButton, Box } from '@papertrail/styleguide'
import React from 'react'

type Props = {
  option: string
  changeOption: (variant: string) => void
}

export default function ViewToggle(props: Props) {
  const { option, changeOption } = props

  function handleChange(event, value) {
    if (value !== null) {
      changeOption(value)
    }
  }

  return (
    <ToggleButtonGroup orientation="horizontal" value={option} onChange={handleChange} exclusive>
      <ToggleButton value="grid" data-testid="grid-view" aria-label="grid">
        <GridViewIcon /> <Box paddingLeft={0.5}>Grid view</Box>
      </ToggleButton>
      <ToggleButton value="list" data-testid="list-view" aria-label="list">
        <ListViewIcon /> <Box paddingLeft={0.5}>List view</Box>
      </ToggleButton>
    </ToggleButtonGroup>
  )
}
