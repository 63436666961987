import React from 'react'
import { Subscription } from 'src/types'
import { useHistory } from 'react-router-dom'
import { SupportPopupConfig } from './SupportPopup'
import SubscriptionRow from './SubscriptionRow'
import SubscriptionCard from './SubscriptionCard'

type Props = {
  subscription: Subscription
  view: string
  showSupportPopup: (config: SupportPopupConfig) => void
}

export default function SubscriptionCardWrapper(props: Props) {
  const { subscription, view, showSupportPopup } = props

  const history = useHistory()

  function onCardClick() {
    if (!subscription.active) {
      if (subscription.isTrial) {
        showSupportPopup({
          title: 'Expired Trial Subscription',
          body: 'This trial has expired. Please get in touch to discuss purchasing a subscription.',
          intercom: 'I would like to activate a demo account'
        })
      } else {
        showSupportPopup({
          title: 'Expired subscription',
          body: 'This subscription has expired. Please get in touch to discuss reactivation.',
          intercom: 'I would like to reactivate a subscription'
        })
      }
    } else {
      history.push(`/subscriptions/${subscription.id}/overview`)
    }
  }

  if (view === 'grid') {
    return <SubscriptionCard key={subscription.id} subscription={subscription} onClick={onCardClick} />
  } else {
    return <SubscriptionRow key={subscription.id} subscription={subscription} onClick={onCardClick} />
  }
}
