import React from 'react'
import { theme, ThemeProvider } from '@papertrail/styleguide'
import { BrowserRouter } from 'react-router-dom'
import Accounts from './Accounts'

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Accounts />
      </BrowserRouter>
    </ThemeProvider>
  )
}
