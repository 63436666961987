import React from 'react'
import { Box } from '@papertrail/styleguide'

type Props = {
  children?: any
}
/*
 * Convenience element for anything which needs to span the card grid
 */
export function GridSpan(props: Props) {
  const styles = {
    gridColumn: '1 / -1',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
  return <Box sx={styles}>{props.children}</Box>
}
