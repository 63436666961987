import React, { useEffect, useState, useCallback } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import SectionHeader from './components/SectionHeader'
import AccountWrapper from './components/AccountWrapper'
import { useApiGetAccounts, useApiGetSubscription } from './hooks'
import SubscriptionCardWrapper from './components/SubscriptionCard.wrapper'
import ViewToggle from './components/ViewToggle'
import { debounce } from 'lodash'
import './Accounts.css'
import { GridSpan } from './components/GridSpan'
import { SupportPopup } from './components/SupportPopup'
import type { SupportPopupConfig } from './components/SupportPopup'
import AccountHeaderTab from './AccountHeaderTab'
import InactiveAccountsBanner from './components/InactiveAccountsBanner'
import { STORAGE_PREFIX } from './consts'

import {
  SearchInput,
  ContentHeader,
  ButtonPrimary,
  Box,
  ToolTip,
  Loading,
  NoData,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  ContentWrapper,
  ContentScrolling,
  HomeIcon,
  Stack,
  InactiveIcon,
  AccountsIcon,
  SubscriptionIcon,
  useMediaQuery,
  Snackbar,
  Alert,
  ManageSearchIcon
} from '@papertrail/styleguide'

const styles = {
  cardContainer: {
    flexShrink: 0,
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(350px, 1fr));',
    justifyContent: 'center',
    columnGap: 4,
    rowGap: 4,
    padding: 2,
    '@media (max-width: 600px)': {
      gridTemplateColumns: '1fr',
      padding: 0,
      columnGap: 0
    }
  },
  tableHeaderCell: {
    color: 'white',
    fontWeight: 'bold',
    backgroundColor: '#354A63'
  }
}

export default function Accounts() {
  const [showSnackbar, setShowSnackbar] = useState(false)
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const searchParam = params.get('search')
  const inactiveParam = params.get('inactive')
  const history = useHistory()
  const [view, setView] = useState('grid')
  const isMobile = useMediaQuery('(max-width:600px)')
  const [supportPopupConfig, setSupportPopupConfig] = useState<SupportPopupConfig>(undefined)
  const [accountsState, loadAccounts, loadMoreAccounts] = useApiGetAccounts(12)
  const [subscriptionsState, loadSubscriptions, loadMoreSubscriptions] = useApiGetSubscription(12)

  useEffect(() => {
    const view = localStorage.getItem(`${STORAGE_PREFIX}.selectedView`)
    if (view) {
      setView(view)
    }
  })

  function navigateHome() {
    navigateToAccounts(searchParam, false)
  }

  function navigateInactive() {
    navigateToAccounts(searchParam, true)
  }

  function changeView(view) {
    setView(view)
    localStorage.setItem(`${STORAGE_PREFIX}.selectedView`, view)
  }

  function reload() {
    if (searchParam && searchParam.length) {
      loadAccounts({ filter: `name~=${searchParam}` }, isInactive)
      loadSubscriptions({ filter: `name~=${searchParam}` }, isInactive)
    } else {
      loadAccounts({}, isInactive)
      loadSubscriptions({}, isInactive)
    }
  }

  function onDemoActivated() {
    setShowSnackbar(true)
    reload()
  }

  const isInactive = !!inactiveParam

  useEffect(() => {
    reload()
  }, [searchParam, isInactive])

  const debouncedNavigateTo = useCallback(debounce(navigateToAccounts, 500), [])

  function search(searchText) {
    debouncedNavigateTo(searchText, isInactive)
  }

  function navigateToAccounts(searchText: string, inactive: boolean) {
    const keys = []

    if (searchText && searchText.length) {
      keys.push({ key: 'search', value: searchText })
    }

    if (inactive) {
      keys.push({ key: 'inactive', value: inactive })
    }

    if (keys.length > 0) {
      const params = keys.map((key) => `${key.key}=${key.value}`).join('&')
      history.push(`/accounts?${params}`)
    } else {
      history.push(`/accounts`)
    }
  }

  const showUniversalSearch = () => {
    const universalSearch = new CustomEvent('universalSearch', {
      detail: {
        show: true
      }
    })
    window.dispatchEvent(universalSearch)
  }

  return (
    <ContentWrapper>
      <ContentHeader>
        <Stack direction="row" sx={{ height: '100%' }} alignItems="center">
          <AccountHeaderTab text="Home" isActive={!isInactive} Icon={HomeIcon} onClick={navigateHome} />
          <AccountHeaderTab text="Inactive" isActive={isInactive} Icon={InactiveIcon} onClick={navigateInactive} />
        </Stack>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={showSnackbar}
          autoHideDuration={6000}
          onClose={() => {
            setShowSnackbar(false)
          }}>
          <Alert severity="success">Your demo account has been activated</Alert>
        </Snackbar>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <SearchInput search={search} initialSearch={searchParam} />
          <div style={{ paddingRight: '20px', cursor: 'pointer' }}>
            <ToolTip title="Search across other accounts">
              <ManageSearchIcon fontSize={'large'} onClick={showUniversalSearch} />
            </ToolTip>
          </div>

          {!isMobile && <ViewToggle option={view} changeOption={changeView} />}
        </Box>
      </ContentHeader>

      {!isInactive && !searchParam && <InactiveAccountsBanner activeAccounts={accountsState.totalCount > 0} />}

      <ContentScrolling variant="grey">
        <Box sx={styles.cardContainer}>
          <SectionHeader
            sectionTitle={`My accounts (${accountsState.totalCount})`}
            buttonLabel="Create New Account"
            buttonRoute="/accounts/new-account"
            showButton={!(subscriptionsState.isLoaded && subscriptionsState.data.length === 0)}
          />

          {accountsState.isLoaded &&
            accountsState.data.length > 0 &&
            (view === 'grid' || isMobile) &&
            accountsState.data.map((account) => (
              <AccountWrapper
                key={account.id}
                account={account}
                isMobile={isMobile}
                view={view}
                showSupportPopup={setSupportPopupConfig}
                demoActivated={onDemoActivated}
                reloadAccounts={reload}
              />
            ))}

          {accountsState.isLoaded && accountsState.data.length === 0 && (
            <GridSpan>
              <NoData icon={AccountsIcon} text="No Accounts" />
            </GridSpan>
          )}

          {accountsState.isLoaded && view === 'list' && !isMobile && accountsState.data.length > 0 && (
            <GridSpan>
              <Table width="100%">
                <TableHead>
                  <TableRow>
                    <TableCell sx={styles.tableHeaderCell} width={1} />
                    <TableCell sx={styles.tableHeaderCell}>Name</TableCell>
                    <TableCell sx={styles.tableHeaderCell}>Warn Filters</TableCell>
                    <TableCell sx={styles.tableHeaderCell}>Alert Filters</TableCell>
                    <TableCell sx={styles.tableHeaderCell} />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {accountsState.data.map((account) => (
                    <AccountWrapper
                      key={account.id}
                      account={account}
                      view={view}
                      isMobile={isMobile}
                      reloadAccounts={reload}
                      showSupportPopup={setSupportPopupConfig}
                      demoActivated={onDemoActivated}
                    />
                  ))}
                </TableBody>
              </Table>
            </GridSpan>
          )}

          {accountsState.isLoading && (
            <GridSpan>
              <Loading />
            </GridSpan>
          )}

          <GridSpan>
            {accountsState.hasMore && (
              <ButtonPrimary loading={accountsState.isLoadingMore} onClick={loadMoreAccounts}>
                Load More
              </ButtonPrimary>
            )}
          </GridSpan>

          <SectionHeader
            sectionTitle={`My subscriptions (${subscriptionsState.totalCount})`}
            buttonLabel="Create New Subscription"
            buttonRoute="/accounts/new-subscription"
          />

          {subscriptionsState.isLoading && (
            <GridSpan>
              <Loading />
            </GridSpan>
          )}

          {subscriptionsState.isLoaded && subscriptionsState.data.length === 0 && (
            <GridSpan>
              <NoData icon={SubscriptionIcon} text="No Subscriptions" />
            </GridSpan>
          )}

          {subscriptionsState.isLoaded &&
            (view === 'grid' || isMobile) &&
            subscriptionsState.data.map((subscription) => (
              <SubscriptionCardWrapper
                view="grid"
                key={subscription.id}
                subscription={subscription}
                showSupportPopup={setSupportPopupConfig}
              />
            ))}

          {subscriptionsState.isLoaded && view === 'list' && !isMobile && (
            <GridSpan>
              <Table width="100%">
                <TableHead>
                  <TableRow>
                    <TableCell sx={styles.tableHeaderCell} width={1} />
                    <TableCell sx={styles.tableHeaderCell}>Name</TableCell>
                    <TableCell sx={styles.tableHeaderCell}>Plan / Package</TableCell>
                    <TableCell sx={styles.tableHeaderCell} />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {subscriptionsState.data.map((subscription) => (
                    <SubscriptionCardWrapper
                      view="list"
                      key={subscription.id}
                      subscription={subscription}
                      showSupportPopup={setSupportPopupConfig}
                    />
                  ))}
                </TableBody>
              </Table>
            </GridSpan>
          )}

          <GridSpan>
            {subscriptionsState.hasMore && (
              <ButtonPrimary loading={subscriptionsState.isLoadingMore} onClick={loadMoreSubscriptions}>
                Load More
              </ButtonPrimary>
            )}
          </GridSpan>
        </Box>
      </ContentScrolling>

      <SupportPopup
        show={supportPopupConfig !== undefined}
        onClose={() => setSupportPopupConfig(undefined)}
        config={supportPopupConfig}
      />
    </ContentWrapper>
  )
}
