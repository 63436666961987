import React from 'react'
import { Subscription } from 'src/types'
import { TableRow, TableCell, SubscriptionIcon, Avatar, colours, Stack, Box } from '@papertrail/styleguide'
import slugify from 'slugify'
import SubscriptionAllowances from './SubscriptionAllowances'
import SubscriptionStatusLabel from './SubscriptionStatusLabel'
import SubscriptionActions from './SubscriptionActions'

type Props = {
  subscription: Subscription
  onClick: () => void
}

const styles = {
  row: {
    backgroundColor: 'white',
    cursor: 'pointer',
    ':hover': {
      backgroundColor: colours.neutral.n100
    }
  }
}

export default function SubscriptionRow(props: Props) {
  const { subscription, onClick } = props

  return (
    <TableRow
      sx={styles.row}
      onClick={onClick}
      data-cy="subscription-row"
      data-testid={'subscription-row-' + slugify(subscription.subName.toLowerCase())}>
      <TableCell>
        <Avatar>
          <SubscriptionIcon fontSize={'large'} />
        </Avatar>
      </TableCell>
      <TableCell>
        <Stack direction="row" justifyContent={'flex-start'} alignItems={'flex-start'} spacing={1}>
          <span>{subscription.subName}</span>
          <SubscriptionStatusLabel subscription={subscription} />
        </Stack>
        <Box sx={{ marginTop: '4px' }}>
          <SubscriptionAllowances subscription={subscription} />
        </Box>
      </TableCell>
      <TableCell>{subscription.planName}</TableCell>
      <TableCell>
        <SubscriptionActions subscription={subscription} />
      </TableCell>
    </TableRow>
  )
}
