import React from 'react'
import { Stack } from '@papertrail/styleguide'

const styles = {
  tab: {
    height: '100%',
    padding: '0 24px',
    cursor: 'pointer',
    '&&:hover': {
      backgroundColor: '#ffffff17'
    },
    '@media (max-width: 600px)': {
      padding: '0 12px'
    }
  },
  active: {
    height: '100%',
    padding: '0 24px',
    cursor: 'pointer',
    backgroundColor: '#007AFF',
    '@media (max-width: 600px)': {
      padding: '0 12px'
    }
  }
}

type Props = {
  text: string
  onClick?: () => void
  isActive: boolean
  Icon: any
}

export default function AccountHeaderTab(props: Props) {
  const { text, isActive, Icon } = props
  return (
    <Stack sx={isActive ? styles.active : styles.tab} direction="row" alignItems="center" onClick={props.onClick}>
      <Icon />
      <span style={{ marginLeft: '2px' }}>{text}</span>
    </Stack>
  )
}
