import React from 'react'
import { ButtonPrimary, Box, FlexRow, Divider } from '@papertrail/styleguide'
import { useHistory } from 'react-router-dom'

type Props = {
  sectionTitle: string
  buttonLabel: string
  buttonRoute: string
}

const styles: any = {
  header: {
    textTransform: 'uppercase'
  }
}

export default function SectionHeader(props: Props & { showButton?: boolean }) {
  const { sectionTitle, buttonLabel, buttonRoute, showButton = true } = props

  const history = useHistory()

  function onClick() {
    history.push(buttonRoute)
  }

  return (
    <FlexRow alignItems={'center'} justifyContent={'space-between'} sx={{ gridColumn: '1 / -1' }}>
      <div style={styles.header}>{sectionTitle}</div>
      <Box flexGrow={1} p={1}>
        <Divider />
      </Box>
      {showButton && <ButtonPrimary onClick={onClick}>{buttonLabel}</ButtonPrimary>}
    </FlexRow>
  )
}
