import React, { useState, useEffect } from 'react'
import { IconButton, Menu, MenuItem, MoreVerticalIcon, Box } from '@papertrail/styleguide'
import { Account } from '../types'
import { useHistory } from 'react-router-dom'
import { useApiActivateAccount, useApiGetReports } from '../hooks'
import AccountCloneDialog from './AccountCloneDialog'
import CalendarDialog from './CalendarDialog'

type Props = {
  account: Account
  demoActivated: (error?: string) => void
  reloadAccounts: () => void
}

export default function AccountActions(props: Props) {
  const { account, demoActivated, reloadAccounts } = props
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const history = useHistory()
  const [cloneDialogOpen, setCloneDialogOpen] = useState(false)
  const { activateAccountState, activateAccount } = useApiActivateAccount(account.id)
  const [reportsState, loadReports] = useApiGetReports(account.id)
  const [calendarDialogOpen, setCalendarDialogOpen] = useState(false)

  useEffect(() => {
    loadReports({})
  }, [])

  useEffect(() => {
    if (activateAccountState.isError) {
      demoActivated(activateAccountState.error.errorCode)
    }
  }, [activateAccountState.isError])

  useEffect(() => {
    if (activateAccountState.isLoaded) {
      demoActivated(null)
    }
  }, [activateAccountState.isLoaded])

  function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  function handleClose() {
    setAnchorEl(null)
  }

  function navigateTo(route: string) {
    history.push(route)
  }

  function activate() {
    handleClose()
    activateAccount()
  }

  const menuItems = [
    <MenuItem
      key="settings"
      onClick={() => navigateTo(`/accounts/${account.id}/settings`)}
      data-testid="account-settings-button">
      Settings
    </MenuItem>,
    <MenuItem key="clone" onClick={() => setCloneDialogOpen(true)} data-testid="account-clone-button">
      Clone
    </MenuItem>,
    <MenuItem
      key="members"
      onClick={() => navigateTo(`/accounts/${account.id}/settings/team/new`)}
      data-testid="account-member-button">
      Invite member
    </MenuItem>
  ]

  if (reportsState.isLoaded && reportsState.data.length > 0) {
    menuItems.push(
      <MenuItem
        key="analytics"
        onClick={() => navigateTo(`/accounts/${account.id}/analytics`)}
        data-testid="account-analytics-button">
        Analytics
      </MenuItem>
    )
  }

  if (account.active || account.isDemo) {
    return (
      <Box
        onClick={(event) => {
          event.stopPropagation()
        }}>
        {/* The problem here is with just the show property, it is in the DOM so you get all the load effects */}
        {cloneDialogOpen && (
          <AccountCloneDialog
            show={cloneDialogOpen}
            onClose={() => {
              setCloneDialogOpen(false)
              handleClose()
            }}
            reloadAccounts={reloadAccounts}
            account={account}
          />
        )}
        <IconButton
          data-testid="account-actions-button"
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}>
          <MoreVerticalIcon />
        </IconButton>

        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}>
          {account.active && (
            <MenuItem onClick={() => navigateTo(`/accounts/${account.id}`)} data-testid="view-account-button">
              View account
            </MenuItem>
          )}

          {account.isDemo && (
            <MenuItem onClick={activate} data-testid="activate-demo-button">
              Activate Demo
            </MenuItem>
          )}

          {account.subscription && account.subscription.features.calendar_subscription && account.member && (
            <MenuItem key="calendar" onClick={() => setCalendarDialogOpen(true)} data-testid="account-calendar-button">
              Calendar
            </MenuItem>
          )}

          {account.active && (account.isAdmin || account.isEmployee) && [menuItems]}
        </Menu>

        {calendarDialogOpen && (
          <CalendarDialog
            account={account}
            onClose={() => setCalendarDialogOpen(false)}
            reloadAccounts={reloadAccounts}
          />
        )}
      </Box>
    )
  } else {
    return null
  }
}
