import React from 'react'
import { Account, Filter } from '../types'
import { FetchManyState } from '@papertrail/web3-utils'
import AccountStatusLabel from './AccountStatusLabel'
import { TableRow, TableCell, colours, Box, Stack } from '@papertrail/styleguide'
import AccountAvatar from './AccountAvatar'
import slugify from 'slugify'
import AccountActions from './AccountActions'

type Props = {
  account: Account
  onClick: () => void
  filtersState: FetchManyState<Filter>
  reloadAccounts: () => void
  demoActivated: (error?: string) => void
}

const styles = {
  warnFilters: {
    color: colours.yellow.y400
  },
  errorFilters: {
    color: colours.red.r400
  },
  row: {
    backgroundColor: 'white',
    cursor: 'pointer',
    ':hover': {
      backgroundColor: colours.neutral.n100
    }
  }
}

export default function AccountRow(props: Props) {
  const { account, filtersState, onClick, reloadAccounts, demoActivated } = props

  function getFilterData(filter: 'warn' | 'alert') {
    return (
      <Stack>
        {filtersState.data &&
          filtersState.data.length > 0 &&
          filtersState.data
            .filter((f: Filter) => f.profile === filter && f.count > 0)
            .map((filter) => <Box key={filter.id}>{`${filter.count} ${filter.name}`}</Box>)}
      </Stack>
    )
  }

  return (
    <TableRow
      data-cy="account-row"
      data-testid={'account-row-' + slugify(account.accountName.toLowerCase())}
      sx={styles.row}
      onClick={onClick}>
      <TableCell>
        <AccountAvatar account={account} />
      </TableCell>
      <TableCell>
        <Stack direction="row" spacing={1} alignItems="center">
          <span>{account.accountName}</span>
          <AccountStatusLabel account={account} />
        </Stack>
        <Box color={colours.neutral.n200}>{account.templateName}</Box>
      </TableCell>
      <TableCell>
        <span style={styles.warnFilters}>{getFilterData('warn')}</span>
      </TableCell>
      <TableCell>
        <span style={styles.errorFilters}>{getFilterData('alert')}</span>
      </TableCell>
      <TableCell>
        <AccountActions account={account} reloadAccounts={reloadAccounts} demoActivated={demoActivated} />
      </TableCell>
    </TableRow>
  )
}
