import * as React from 'react'
import { Stack } from '@papertrail/styleguide'

type Props = {
  url: string
}

export default function CopyLink(props: Props) {
  const { url } = props

  const inputStyle = {
    flex: 1,
    border: '1px solid #ccc',
    borderRadius: '3px 0 0 3px ',
    color: '#777',
    paddingLeft: '5px',
    backgroundColor: '#eee',
    outline: 'none'
  }

  const buttonStyle = {
    paddingLeft: '12px',
    paddingRight: '12px',
    height: '32px',
    backgroundColor: 'white',
    border: '1px solid #ccc',
    borderRadius: '0 3px 3px 0',
    borderWidth: '1px 1px 1px 0',
    cursor: 'pointer'
  }

  async function copyLink(e) {
    e.preventDefault()
    await navigator.clipboard.writeText(url)
    alert('Url copied to clipboard.')
  }

  return (
    <Stack flexDirection={'row'}>
      <input style={inputStyle} readOnly type="text" value={url} />
      <button style={buttonStyle} onClick={copyLink}>
        Copy URL
      </button>
    </Stack>
  )
}
