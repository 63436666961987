import React, { useEffect, useState } from 'react'
import { useApiGetRecentlyDeactivatedAccounts } from '../hooks'
import { Collapse, ColourBox, Box, WarningIcon, CloseIcon, IconButton, TextButton } from '@papertrail/styleguide'
import { useHistory } from 'react-router-dom'
import { compareDateTime } from '@papertrail/web3-utils'

type Props = {
  activeAccounts: boolean
}

/*
 Show a banner if:
  1. There are no active accounts and some inactive.
  2. There are active accounts but some were deactivated recently.
 */

export default function InactiveAccountsBanner(props: Props) {
  const [inactiveState, loadInactiveState] = useApiGetRecentlyDeactivatedAccounts()
  const [showBanner, setShowBanner] = useState(true)
  const history = useHistory()

  useEffect(() => {
    loadInactiveState()
  }, [])

  function isRecent(theDate) {
    if (theDate) {
      const delta = compareDateTime(theDate)

      if (delta > -15) {
        return true
      }
    }
    return false
  }

  function hasRecentInactive() {
    return hasInactive() && isRecent(inactiveState.data[0].deactivatedAt)
  }

  function hasOnlyInactive() {
    return !props.activeAccounts && hasInactive()
  }

  function hasInactive() {
    return inactiveState.isLoaded && inactiveState.data.length > 0
  }

  function getMessage() {
    if (hasRecentInactive()) {
      return 'Some of your accounts were recently moved to the inactive tab'
    } else if (hasOnlyInactive()) {
      return 'You do not have access to any active accounts'
    }
  }

  return (
    <Box flexGrow={1}>
      <Collapse in={(hasRecentInactive() || hasOnlyInactive()) && showBanner}>
        <ColourBox
          severity="warning"
          paddingLeft={1}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}>
          <WarningIcon fontSize={'large'} />
          <Box flexGrow={1} px={1}>
            {getMessage()}
          </Box>
          <TextButton colour={'inherit'} onClick={() => history.push('/accounts?inactive=true')}>
            VIEW INACTIVE ACCOUNTS
          </TextButton>
          <IconButton onClick={() => setShowBanner(false)}>
            <CloseIcon fontSize={'large'} />
          </IconButton>
        </ColourBox>
      </Collapse>
    </Box>
  )
}
