import React from 'react'
import { PapertrailSession } from '@papertrail/web3-session'
import {
  ButtonPrimary,
  ButtonSecondary,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Divider
} from '@papertrail/styleguide'

type Props = {
  show: boolean
  onClose: () => void
  config: SupportPopupConfig
}

export type SupportPopupConfig = {
  title: string
  body: string
  intercom?: string
}

/**
 * A dialog with a link to contact support
 */
export function SupportPopup(props: Props) {
  function openIntercom() {
    PapertrailSession.intercom.message(props.config.intercom)
    props.onClose()
  }
  if (props.config) {
    return (
      <Dialog open={props.show} onClose={props.onClose}>
        <DialogTitle>{props.config.title}</DialogTitle>
        <Divider />
        <DialogContent sx={{ maxWidth: '400px' }}>
          <DialogContentText>{props.config.body}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <ButtonSecondary onClick={() => props.onClose()}>Close</ButtonSecondary>
          {props.config.intercom && <ButtonPrimary onClick={openIntercom}>Contact Support</ButtonPrimary>}
        </DialogActions>
      </Dialog>
    )
  } else {
    return null
  }
}
